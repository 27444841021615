@import 'variables';

.md-sidemenu .md-button {
  width: 100%;
  height: 48px;
  min-height: 48px;
  margin: 0;
  padding: 0 16px;
  border-radius: 0;
  background-color: #fff;
  text-align: left;
  text-rendering: optimizeLegibility;
  text-transform: none;

  &:hover {
    background-color: #eee;
  }
}

.md-sidemenu .md-subheader {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
}

md-sidemenu-button {
  width: 100%;
  display: flex;
}

.md-sidemenu-button md-icon:first-child {
  max-width: 24px;
  margin-right: 32px;
}

.md-sidemenu-button span {
  flex: 1;
}

.md-sidemenu-button .md-ripple-container {
  border-radius: 0;
}

.md-sidemenu-toggle md-icon {
  transition: .4s transform cubic-bezier(.25, .8, .25, 1);
}

.md-sidemenu-content {
  width: 100%;
  min-height: 48px;
  overflow: hidden;
}

.md-sidemenu .md-sidemenu-toggle {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-flow: column;
  z-index: 1;
  transition: .4s cubic-bezier(.25, .8, .25, 1);
}

.md-sidemenu-toggle > div {
  flex: 1;
  display: flex;
  align-items: center;
}

.md-sidemenu-toggle md-icon:first-child {
  max-width: 24px;
  margin-right: 32px;
}

.md-sidemenu-toggle.md-active md-icon:last-child {
  transform: rotate(180deg) translateZ(0);
}

.md-sidemenu-wrapper {
  margin-top: -120%;
  overflow: hidden;
  opacity: 0;
  transform: translate3D(0, 0, 0);
  transition: .4s cubic-bezier(.55, 0, .55, .2);
  transition-property: transform, opacity, margin, background;

  &.md-active {
    margin-top: 0;
    opacity: 1;
    transition-timing-function: cubic-bezier(.25, .8, .25, 1);
  }
}

.md-sidemenu-wrapper .md-button {
  padding-left: 32px;
}

.md-sidemenu-wrapper.md-sidemenu-wrapper-icons .md-button {
  padding-left: 72px;
}

.md-sidemenu md-divider {
  width: 100%;
  margin: 8px 0;
}

.md-sidemenu md-divider + .md-subheader {
  margin-top: -8px;
}
